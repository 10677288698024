// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
    

    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{
        background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

.drops-title{
    color: #300320;
}

.drops-shade-1{
    color: #fde7f5;
}
.drops-shade-2{
    color: #fab7e2;
}
.drops-shade-3{
    color: #f787cf;
}
.drops-shade-4{
    color: #f457bc;
}
.drops-shade-5{
    color: #f127a9;
}
.drops-shade-6{
    color: #d80e90;
}
.drops-shade-7{
    color: #a80b70;
}
.drops-shade-8{
    color: #780850;
}
.drops-shade-9{
    color: #480530;
}
.drops-shade-10{
    color: #180210;
}

.drops-bg-shade-1{
    color: #180210;
    background-color: #c5c8ec;
}
.drops-bg-shade-2{
    color: #180210;
    background-color: #fab7e2;
}
.drops-bg-shade-7{
    color: #eee;
    background-color: #a80b70;
    &:hover {
        background-color: #780850;
        color: #eee;
    }
}
.drops-bg-shade-8{
    color: #eee;
    background-color: #780850;
    &:hover {
        background-color: #a80b70;
        color: #eee;
    }
}
.drops-bg-shade-9{
    color: #eee;
    background-color: #480530;
    &:hover {
        background-color: #780850;
        color: #eee;
    }
}
.drops-bg-shade-10{
    color: #eee;
    background-color: #180210;
    &:hover {
        background-color: #780850;
        color: #eee;
    }
}

.drops-bg-shade-overlay-9{
    color: #eee;
    background-color: #480530;
    opacity: 0.7;
}

.drops-bg-shade-overlay-8{
    color: #eee;
    background-color: #780850;
    opacity: 0.7;
}

.border-radius-sm{
    border-radius: 2px;
}
.full-height{
    min-height: calc(100vh - 70px);
}
.custom-error {
    width: 100%;
    margin-top: .25rem;
    font-size: 100%;
    color: #f46a6a;
}

.bg-print{
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.custom-bg-highlight{
    background-color: #fdff32;
}

.pub-home-body {
    margin: 0;
    margin-top: -6px;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #e6c6eb;
    text-align: left;
    background-color: #341439;
    height: 100vh;
  }

  .navbar.navbar-transparent {
    background: transparent !important;
    padding-top: 25px;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
    background-color: #344675;
  }

  @mixin linear-gradient($color, $states-color){
    background: $color;
    background: -webkit-linear-gradient(0deg, $color 0%, $states-color 100%);
    background: -o-linear-gradient(0deg, $color 0%, $states-color 100%);
    background: -moz-linear-gradient(0deg, $color 0%, $states-color 100%);
    background: linear-gradient(0deg, $color 0%, $states-color 100%);
}

.header-filter{
    position: relative;

    .container{
        z-index: 2;
        position: relative;
    }

}

.page-header {
    //height: 100vh;
    .container{
        > .content-center {
            &.brand {
              .h1-seo,
              h3{
                color: $white;
                font-weight: 600;
                text-transform: capitalize;
                span{
                  font-weight: $font-weight-bold;
                }
            }
          }
        }
    }

    .category-absolute {
      position: absolute;
      top: 100vh;
      margin-top: -60px;
      padding: 0 15px;
      width: 100%;
      color: rgba(255,255,255,.5);
    }
}

.drops-rotate {
    //-webkit-transform: rotate(45deg);
    //transform: rotate(45deg);
    position:fixed;
    width: 80vw;
    height: 100vh;
}

.cricket-anim {
    position: fixed;
    width: 100px;
    height: 100px;
    opacity: 0.5;
    right: 27%;
    top: 30%;
    background-image: url("../../../images/cricket.png");
    background-size: contain;
    animation: move-left-right 10s infinite;
    filter: invert(49%) sepia(81%) saturate(3063%) hue-rotate(163deg) brightness(96%) contrast(102%);
  }

  .football-anim {
    position: fixed;
    width: 100px;
    height: 100px;
    opacity: 0.5;
    left: 30%;
    bottom: 50%;
    background-image: url("../../../images/football.png");
    background-size: contain;
    animation: move-top-bottom 5s infinite;
    filter: invert(49%) sepia(81%) saturate(3063%) hue-rotate(163deg) brightness(96%) contrast(102%);
  }

  .shuttle-anim {
    position: fixed;
    width: 100px;
    height: 100px;
    opacity: 0.5;
    right: 45%;
    top: 45%;
    background-image: url("../../../images/shuttle.png");
    background-size: contain;
    animation: move-top-bottom 9s infinite;
    filter: invert(49%) sepia(81%) saturate(3063%) hue-rotate(163deg) brightness(96%) contrast(102%);
  }

  .bowling-anim {
    position: fixed;
    width: 100px;
    height: 100px;
    opacity: 0.5;
    left: 40%;
    bottom: 80%;
    background-image: url("../../../images/bowling.png");
    background-size: contain;
    animation: move-left-right 10s infinite;
    filter: invert(49%) sepia(81%) saturate(3063%) hue-rotate(163deg) brightness(96%) contrast(102%);
  }

  .tennis-anim {
    position: fixed;
    width: 100px;
    height: 100px;
    opacity: 0.5;
    left: 20%;
    bottom: 72%;
    background-image: url("../../../images/tennis.png");
    background-size: contain;
    animation: move-left-right 5s infinite;
    filter: invert(49%) sepia(81%) saturate(3063%) hue-rotate(163deg) brightness(96%) contrast(102%);
  }

  @media (max-width: 600px) {
    .tennis-anim {
        width: 50px;
        height: 50px;
    }
    .bowling-anim {
        width: 50px;
        height: 50px;
    }
    .shuttle-anim {
        width: 50px;
        height: 50px;
    }
    .football-anim {
        width: 50px;
        height: 50px;
    }
    .cricket-anim {
        width: 50px;
        height: 50px;
    }
  }
@keyframes moveAndScale {
    0%, 100% {
      transform: scale(1);
      background-position: 0 0;
    }
    50% {
      transform: scale(1.2);
      background-position: 100% 0;
    }
  }

    .squares{
      animation: move-left-right 1s infinite;
      @include linear-gradient(#56215f, #c57ad1);
      //position: absolute;
      position:fixed;
      //display: inline-block;
      transition: 0.5s ease-out;
      border-radius:0% 100% 100% 100%;
      //-webkit-transform: rotate(45deg);
      //transform: rotate(45deg); 
	    //clear: left;
        &.cricket{
            animation: move-left-right 4s infinite;
            background: url("../../../images/cricket.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.5;
            left: -5%;
            bottom: 0%;
        }
      &.square1{
        animation: move-left-right 4s infinite;
        height: 150px;
        width: 150px;
        opacity: 1;
        left: 3%;
        top: -21%;
      }

      &.square2{
        animation: move-left-right 6s infinite;
        height: 200px;
        width: 200px;
        opacity: 0.4;
        right: -5%;
        top: -12%;
      }

      &.square3{
        animation: move-left-right 5s infinite;
        height: 200px;
        width: 200px;
        
      }

      &.square4{
        animation: move-left-right 10s infinite;
        height: 100px;
        width: 100px;
        opacity: 0.9;
        right: 27%;
        top: 70%;
      }

      &.square5{
        animation: move-left-right 6s infinite;
        height: 250px;
        width: 250px;
        opacity: 0.1;
        left: 32%;
        bottom: 29%;
      }

      &.square6{
        animation: move-top-bottom 9s infinite;
        left: 10%;
        top: 35%;
        height: 80px;
        width: 80px;
        opacity: 0.5;
      }

      &.square7{
        animation: move-top-bottom 3s infinite;
        width: 300px;
        height: 300px;
        right: -5%;
        bottom: 0%;
        opacity: 0.1;
      }
    }

    @keyframes move-left-right{
        0%{
          transform: translateX(-20px);
        }
      
        50%{
          transform: translateX(20px);
        }
      
        100%{
          transform: translateX(-20px);
        }
      }

      @keyframes move-top-bottom{
        0%{
          transform: translateY(-20px);
        }
      
        50%{
          transform: translateY(20px);
        }
      
        100%{
          transform: translateY(-20px);
        }
      }
      
      .header-filter{
        position: relative;
    
        .container{
            z-index: 2;
            position: relative;
        }
    
    }
    .clear-filter{
        &:before{
            display: none;
        }
    }


    .pointer-cursor {
        cursor: pointer;
    }
    .pointer-cursor-with-hover {
        cursor: pointer;
    }
    .pointer-cursor-with-hover:hover {
        background-color: #f0ce7e;
    }

    .opacity-9{
        opacity: 0.9;
    }

    .text-justify{
        text-align: justify;
        text-justify: inter-word;
    }

    .border-1 {
        border: 1px solid #300320;
    }

    .or-line {
        display: flex;
        align-items: center;
    }
    
    .line {
        flex: 1;
        height: 1px;
        background-color:#c2bebe;
    }
    
    .or-text {
        padding: 0 10px;
        //font-weight: bold;
        font-size: 18px;
    }

    /* CSS for the popup overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* CSS for the popup content */
  .popup-content {
    background-color: #fff; /* White background for the popup */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow for a slight depth effect */
    padding: 20px;
    max-width: 80%; /* Adjust the width as needed */
    max-height: 80%; /* Adjust the height as needed */
    overflow: auto; /* Enable scrolling for the content if it exceeds the popup size */
    display: flex;
    flex-direction: column;
  }
  
  /* Optionally, you can center the ImageGallery inside the popup content */
  .popup-content .image-gallery-slide-wrapper {
    display: flex;
    justify-content: center;
  }
  .close-button {
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }